* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
