@import './prismjs';

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background-color: #f4f6f8;
	font-size: 0.825rem;
	font-family: 'Roboto', 'Helvetica', Arial, sans-serif;
	line-height: 150%;
}

a {
	text-decoration: none;
}

:not(pre) > code {
	font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
	background-color: #eeeeee;
	padding: 2px 4px;
	direction: ltr;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	line-height: 1.5;
	font-size: 14px;
}
